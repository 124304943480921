var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("setup-table", {
        attrs: {
          items: _vm.items,
          headers: _vm.headers,
          loading: _vm.loading,
          clearItem: !_vm.showForm || !_vm.editedItem.id,
          hideCreate: "",
          hideRowSelect: "",
          "hide-menu": "",
          "flip-table": "",
          showSearch: "",
          pagination: "",
        },
        on: { editItem: _vm.editItem },
        scopedSlots: _vm._u([
          {
            key: "itemForm",
            fn: function () {
              return [
                _c("div", { staticClass: "d-flex align-center col col-12" }, [
                  _c("p", { staticClass: "text-h5" }, [
                    _vm._v("Student Roster"),
                  ]),
                ]),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "12" } },
                      [
                        _c("v-card", [
                          _c(
                            "div",
                            { staticClass: "pt-4 px-4" },
                            [
                              _c("v-select", {
                                staticClass: "mb-0",
                                attrs: {
                                  label: "School",
                                  value: _vm.schoolLocations[_vm.schoolId.name],
                                  items: _vm.schoolLocations,
                                  "item-text": "name",
                                  "item-value": "id",
                                  outlined: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.getItems(_vm.schoolId)
                                  },
                                },
                                model: {
                                  value: _vm.schoolId,
                                  callback: function ($$v) {
                                    _vm.schoolId = $$v
                                  },
                                  expression: "schoolId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }